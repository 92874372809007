import { useMutation, useQuery } from "react-query";

import { useCreateAPIClient } from "../../../hooks";
import { PurchaseSubscriptionRequest } from "../../../api/stripe/types";
import { useToggle } from "../../toggle/useToggle";

export const useCreateSetupIntent = () => {
  const apiClient = useCreateAPIClient();

  return useMutation(["setupIntent", "create"], () =>
    apiClient.stripeApi.createSetupIntent(),
  );
};

export const usePurchaseSubscription = () => {
  const apiClient = useCreateAPIClient();
  const isNewWebPricing = useToggle("featureWebPricingTwo");

  return useMutation(
    ["subscription", "purchase"],
    (payload: PurchaseSubscriptionRequest) =>
      apiClient.stripeApi.purchaseSubscription({ ...payload, isNewWebPricing }),
  );
};

export const useHOTNGetOfferInfo = (couponCode: string) => {
  const apiClient = useCreateAPIClient();

  return useQuery({
    queryKey: ["userSubscription"],
    queryFn: () => apiClient.stripeApi.HOTNGetOfferInfo(couponCode),
    enabled: !!couponCode,
  });
};

export const useHOTNPreCheck = (
  payload: Parameters<
    ReturnType<typeof useCreateAPIClient>["stripeApi"]["HOTNPreCheck"]
  >[0],
  enabled: boolean,
) => {
  const apiClient = useCreateAPIClient();

  return useQuery({
    queryKey: ["userSubscription"],
    queryFn: () => apiClient.stripeApi.HOTNPreCheck(payload),
    enabled,
  });
};

export const useHOTNCreateSetupIntent = () => {
  const apiClient = useCreateAPIClient();

  return useMutation(["setupIntent", "create"], (secret: string) =>
    apiClient.stripeApi.HOTNCreateSetupIntent(secret),
  );
};

export const useHOTNPurchaseOffer = () => {
  const apiClient = useCreateAPIClient();

  return useMutation(
    ["subscription", "purchase"],
    (
      payload: Parameters<
        ReturnType<typeof useCreateAPIClient>["stripeApi"]["HOTNPurchaseOffer"]
      >[0],
    ) => apiClient.stripeApi.HOTNPurchaseOffer(payload),
  );
};
