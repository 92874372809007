import { useCallback, useEffect, useMemo, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Cookies from "js-cookie";

import { hasRoleAccess, makeRedirect } from "../../utils/utils";
import { userLoginLandingPage } from "../../utils/constants";
import { usePaywall } from "../../hooks/usePaywall";
import { useRouter } from "next/router";
import { getBrowserFingerprint, identify } from "../../utils/monitoring";
import { useUser } from "../../hooks";
import { useIsSubscribed } from "../../features/subscriptions/hooks/useIsSubscribed";
import { useDebounce } from "../../hooks/useDebounce";
import { useQueryClient } from "react-query";

export const useAuth = () => {
  const router = useRouter();
  const hasValidSubscription = useIsSubscribed();
  const queryClient = useQueryClient();

  const checkTokenExpiration = useStoreActions(
    (a) => (a.auth as any).checkTokenExpiration,
  );
  const fetchUserProfile = useStoreActions(
    (a) => (a.profile as any).fetchUserProfile,
  );

  const logoutAction = useStoreActions(
    (actions) => (actions.auth as any).logOut,
  );

  const user = useStoreState((s) => s.profile.userData);
  const isAuthenticated = useMemo(() => !!user._id, [user._id]);

  useEffect(() => {
    checkTokenExpiration().then(({ isValidUser }) => {
      if (isValidUser) fetchUserProfile({ token: Cookies.get("kic_token") });
    });
  }, [router.pathname]);

  useDebounce(
    () => {
      identify(user);
    },
    1000,
    [user.email, user._id, user.first_name],
  );

  const logout = useCallback((redirectToLogin = true) => {
    if (redirectToLogin) router.push("/login");
    logoutAction();
    queryClient.clear();
  }, []);

  return {
    user: user._id ? user : null,
    isAuthenticated,
    isAdmin: hasRoleAccess(user?.roles, 5),
    hasValidSubscription,
    logout,
  };
};

const unAuthOnlyRoutes = ["/", "/login"];

export const useRedirect = ({
  requiresAuth,
  isAuthenticated,
  isAdmin,
  isAdminPages,
  pathname,
  asPath,
}: {
  requiresAuth: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isAdminPages: boolean;
  pathname: string;
  asPath: string;
}) => {
  const [willRedirect, setWillRedirect] = useState(true);
  const redirect = (...args) => {
    makeRedirect(...args);
    setWillRedirect(true);
  };
  const { showPaywall } = usePaywall();

  useEffect(() => {
    if (
      isAuthenticated &&
      (unAuthOnlyRoutes.includes(asPath) || asPath.includes("/?"))
    ) {
      redirect({}, userLoginLandingPage, { noNextUrl: true });
      return;
    }

    if (!requiresAuth) {
      if (!isAuthenticated) {
        if (pathname === "/") {
          redirect({}, "/login", { noNextUrl: true });
          return;
        }

        setWillRedirect(false);
        return;
      }

      if (pathname === "/resetpassword") {
        redirect({}, userLoginLandingPage, {
          shallow: true,
          noNextUrl: true,
        });
        return;
      }

      setWillRedirect(false);
    }

    if (!isAuthenticated) {
      redirect({ asPath }, "/login");
      return;
    }

    if (!isAdmin && isAdminPages) {
      redirect({}, userLoginLandingPage, {
        noNextUrl: true,
        shallow: true,
      });
      return;
    }

    if (showPaywall) {
      redirect({}, "/dashboard/profile/membership?modal=true", {
        noNextUrl: true,
        dynamicRoute: {
          pathname: "/dashboard/profile/[tab]?modal=true",
          asPath: "/dashboard/profile/membership",
        },
      });
      return;
    }

    setWillRedirect(false);
  }, [
    pathname,
    asPath,
    requiresAuth,
    isAuthenticated,
    isAdmin,
    isAdminPages,
    showPaywall,
  ]);

  return willRedirect;
};

export const useUpdateUserAttributionEffect = () => {
  const user = useUser();
  const updateUserProfile = useStoreActions(
    (actions) => (actions.profile as any).updateUserProfile,
  );

  const updateUserAttribution = useCallback(async () => {
    await updateUserProfile({ attribution: await getBrowserFingerprint() });
  }, [getBrowserFingerprint, updateUserProfile]);

  useEffect(() => {
    updateUserAttribution();
  }, [user._id]);

  return { updateUserAttribution };
};
