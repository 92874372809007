import {
  PurchaseSubscriptionRequest,
  PurchaseSubscriptionResponse,
  PaymentIntentRequest,
  PaymentIntentResponse,
} from "./types";
import { SendRequest } from "../makeSendRequest";
import { CurrencyType, UserSubscription } from "../types";

export const createStripeApi = (sendRequest: SendRequest) => ({
  createGiftCardPaymentIntent: (payload: PaymentIntentRequest) => {
    return sendRequest<PaymentIntentResponse>({
      method: "post",
      path: "stripe/gift-card/create-payment-intent",
      version: "v1",
      data: payload,
      options: {
        requiresAuth: false,
      },
    });
  },
  purchaseSubscription: (payload: PurchaseSubscriptionRequest) => {
    return sendRequest<PurchaseSubscriptionResponse>({
      method: "post",
      path: "stripe/subscription/purchase",
      version: "v1",
      data: payload,
    });
  },

  createSetupIntent: () =>
    sendRequest<PaymentIntentResponse>({
      method: "post",
      path: "stripe/create-setup-intent",
      version: "v1",
    }),

  HOTNGetOfferInfo: (couponCode: string) =>
    sendRequest<{ url: string; description: string }>({
      method: "post",
      path: "stripe/hotn-get-offer-info",
      version: "v1",
      data: { couponCode },
    }),

  HOTNPreCheck: (payload: {
    secret: string;
    planId: string;
    couponCode: string;
    segment?: string;
  }) =>
    sendRequest<{
      email: string;
      trialEndDate: number;
    }>({
      method: "post",
      path: "stripe/hotn-pre-check",
      version: "v1",
      options: {
        requiresAuth: false,
      },
      data: payload,
    }),

  HOTNCreateSetupIntent: (secret: string) =>
    sendRequest<PaymentIntentResponse>({
      method: "post",
      path: "stripe/hotn-create-setup-intent",
      version: "v1",
      options: {
        requiresAuth: false,
      },
      data: { secret },
    }),

  HOTNPurchaseOffer: (payload: {
    secret: string;
    planId: string;
    couponCode: string;
    paymentMethodId: string;
    iso: string;
  }) => {
    return sendRequest<{
      email: string;
      currency: CurrencyType;
      period: string;
      firstChargeDate: number;
      firstCharge: number;
      renewingDate: number;
      renewingCharge: number;
    }>({
      method: "post",
      path: "stripe/subscription/hotn-purchase-offer",
      version: "v1",
      data: payload,
      options: {
        requiresAuth: false,
      },
    });
  },

  cancelSubscription: () =>
    sendRequest<{
      /** @deprecated this sub data does not come from RevenueCat */
      subscription: UserSubscription;
    }>({
      method: "get",
      path: "stripe/subscription/cancel",
      version: "v1",
    }),

  reactivateSubscription: () =>
    sendRequest<{
      /** @deprecated this sub data does not come from RevenueCat */
      subscription: UserSubscription;
    }>({
      method: "get",
      path: "stripe/subscription/reactivate",
      version: "v1",
    }),

  updateSubscription: (payload: {
    subscriptionId: string;
    coupon?: string;
    iso: string;
  }) =>
    sendRequest<{
      /** @deprecated this sub data does not come from RevenueCat */
      subscription: UserSubscription;
    }>({
      method: "post",
      path: "stripe/subscription/change",
      version: "v1",
      data: payload,
    }),

  queueDiscount: (payload: {
    subscriptionId: string;
    coupon: string;
    iso: string;
    isNewWebPricing: boolean;
  }) =>
    sendRequest<{ status: "updated" }>({
      method: "post",
      path: "stripe/subscription/queue-discount",
      version: "v1",
      data: payload,
    }),
});
